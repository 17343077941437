const actions = {
    FETCH_ENROLLMENT_REFERRAL_SOURCE: "FETCH_ENROLLMENT_REFERRAL_SOURCE",
    FETCH_ENROLLMENT_REFERRAL_SOURCE_SUCCESS: "FETCH_ENROLLMENT_REFERRAL_SOURCE_SUCCESS",
    FETCH_ENROLLMENT_REFERRAL_SOURCE_FAILED: "FETCH_ENROLLMENT_REFERRAL_SOURCE_FAILED",
    FETCH_TRIAL_CLASS_REFERRAL_SOURCE: "FETCH_TRIAL_CLASS_REFERRAL_SOURCE",
    FETCH_TRIAL_CLASS_REFERRAL_SOURCE_SUCCESS: "FETCH_TRIAL_CLASS_REFERRAL_SOURCE_SUCCESS",
    FETCH_TRIAL_CLASS_REFERRAL_SOURCE_FAILED: "FETCH_TRIAL_CLASS_REFERRAL_SOURCE_FAILED",
    FETCH_APPOINTMENT_REFERRAL_SOURCE: "FETCH_APPOINTMENT_REFERRAL_SOURCE",
    FETCH_APPOINTMENT_REFERRAL_SOURCE_SUCCESS: "FETCH_APPOINTMENT_REFERRAL_SOURCE_SUCCESS",
    FETCH_APPOINTMENT_REFERRAL_SOURCE_FAILED: "FETCH_APPOINTMENT_REFERRAL_SOURCE_FAILED",

    fetchEnrollmentReferralSource: (
        page: number,
        pagerSize: number,
        resolve: (data: string) => void,
        reject: (value: string) => void
    ) => ({
        type: actions.FETCH_ENROLLMENT_REFERRAL_SOURCE,
        page,
        pagerSize,
        resolve,
        reject,
    }),

    fetchTrialClassReferralSource: (
        page: number,
        pagerSize: number,
        resolve: (data: string) => void,
        reject: (value: string) => void
    ) => ({
        type: actions.FETCH_TRIAL_CLASS_REFERRAL_SOURCE,
        page,
        pagerSize,
        resolve,
        reject,
    }),

    fetchAppointmentReferralSource: (
        page: number,
        pagerSize: number,
        resolve: (data: string) => void,
        reject: (value: string) => void
    ) => ({
        type: actions.FETCH_APPOINTMENT_REFERRAL_SOURCE,
        page,
        pagerSize,
        resolve,
        reject,
    })
};

export default actions;
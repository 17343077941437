import ReferralSourceReducer from "./reducers";
import ReferralSourceSaga from "./sagas";
import referralSourceActions from "./actions";

export const referralSourceReducer = {
    referralSourceReducer: ReferralSourceReducer,
};

export const referralSourceSaga = [ReferralSourceSaga()];

export { referralSourceActions };

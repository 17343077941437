import { AnyAction } from "@reduxjs/toolkit";
import actions from "./actions";
const { fromJS, Map } = require("immutable");

const initialState = fromJS(
    Map({
        loading: false,
        enrollmentReferralSource: [],
        trialClassReferralSource: [],
        appointmentReferralSource: [],
    })
);

export interface Action {
    type: string;
    payload?: any;
    event?: string;
}

const reducer = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case actions.FETCH_APPOINTMENT_REFERRAL_SOURCE, actions.FETCH_ENROLLMENT_REFERRAL_SOURCE, actions.FETCH_TRIAL_CLASS_REFERRAL_SOURCE:
            return state.setIn(["loading"], true)

        case actions.FETCH_APPOINTMENT_REFERRAL_SOURCE_SUCCESS:
            return state.merge({
                loading: false,
                appointmentReferralSource: action.payload
            });

        case actions.FETCH_ENROLLMENT_REFERRAL_SOURCE_SUCCESS:
            return state.merge({
                loading: false,
                enrollmentReferralSource: action.payload,
            });

        case actions.FETCH_TRIAL_CLASS_REFERRAL_SOURCE_SUCCESS:
            return state.merge({
                loading: false,
                trialClassReferralSource: action.payload,
            });

        case actions.FETCH_APPOINTMENT_REFERRAL_SOURCE_FAILED, actions.FETCH_ENROLLMENT_REFERRAL_SOURCE_FAILED, actions.FETCH_TRIAL_CLASS_REFERRAL_SOURCE_FAILED:
            return state.setIn(["loading"], false)

        default:
            return state;
    }
}

export default reducer;
import { AnyAction } from "@reduxjs/toolkit";
import { takeLatest, put, call, all, Effect } from "redux-saga/effects";
import Api from "../../../services/api";
import actions from "./actions";
import { urlGenerator } from "utils/Helpers";

const api = new Api();

interface ReturnTypes {
  [x: string]: string | number | Array<object> | object;
}

interface ReferralSourceType {
  [x: string]: string;
}

const fetchEnrollmentReferralSource = takeLatest(
  actions.FETCH_ENROLLMENT_REFERRAL_SOURCE,
  function* (
    action: AnyAction
  ): Generator<Effect, void, ReferralSourceType> {
    try {
      const response = yield call(
        api.get,
        urlGenerator("api/referralSource", action)
      );
      yield put({
        type: actions.FETCH_ENROLLMENT_REFERRAL_SOURCE_SUCCESS,
        payload: response,
      });
      yield call(action.resolve, response);
    } catch (error) {
      yield put({ type: actions.FETCH_ENROLLMENT_REFERRAL_SOURCE_FAILED });
      yield call(action.reject);
    }
  }
);

const fetchTrialClassReferralSource = takeLatest(
  actions.FETCH_TRIAL_CLASS_REFERRAL_SOURCE,
  function* (
    action: AnyAction
  ): Generator<Effect, void, ReferralSourceType> {
    try {
      const response = yield call(
        api.get,
        urlGenerator("api/referralSource/trial-class", action)
      );
      yield put({
        type: actions.FETCH_TRIAL_CLASS_REFERRAL_SOURCE_SUCCESS,
        payload: response,
      });
      yield call(action.resolve, response);
    } catch (error) {
      yield put({ type: actions.FETCH_TRIAL_CLASS_REFERRAL_SOURCE_FAILED });
      yield call(action.reject);
    }
  }
);

const fetchAppointmentReferralSource = takeLatest(
  actions.FETCH_APPOINTMENT_REFERRAL_SOURCE,
  function* (
    action: AnyAction
  ): Generator<Effect, void, ReferralSourceType> {
    try {
      const response = yield call(
        api.get,
        urlGenerator("api/referralSource/appointment", action)
      );
      yield put({
        type: actions.FETCH_APPOINTMENT_REFERRAL_SOURCE_SUCCESS,
        payload: response,
      });
      yield call(action.resolve, response);
    } catch (error) {
      yield put({ type: actions.FETCH_APPOINTMENT_REFERRAL_SOURCE_FAILED });
      yield call(action.reject);
    }
  }
);

export default function* saga() {
  yield all([
    fetchEnrollmentReferralSource,
    fetchTrialClassReferralSource,
    fetchAppointmentReferralSource
  ]);
}

import { AnyAction } from "@reduxjs/toolkit";
import { takeLatest, put, call, all, Effect } from "redux-saga/effects";
import Api from "../../../services/api";
import actions from "./actions";
import { urlGenerator } from "utils/Helpers";

const api = new Api();

interface ReturnTypes {
  [x: string]: string | number | Array<object> | object;
}

interface EmployeeAttendanceReturnType {
    [x: string]: string;
}

const createAttendance = takeLatest(
    actions.CREATE_EMPLOYEE_ATTENDANCE,
    function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
      try {
        const response = yield call(
          api.post,
          `api/employeeAttendance`,
          action.data,
          null
        );
        yield put({
          type: actions.CREATE_EMPLOYEE_ATTENDANCE_SUCCESS,
          payload: response,
        });
        yield call(action.resolve, response);
      } catch (error) {
        yield put({ type: actions.CREATE_EMPLOYEE_ATTENDANCE_FAILED });
        yield call(action.reject, "reject");
      }
    }
  );

  const fetchAttendances = takeLatest(
    actions.FETCH_EMPLOYEE_ATTENDANCES,
    function* (action: AnyAction): Generator<Effect, void, EmployeeAttendanceReturnType> {
    const { branch_id, resolve, reject, time_zone } = action;
      try {
        const response = yield call(api.get,  
            urlGenerator(`api/employeeAttendance?time_zone=${time_zone}&&branch_id=${branch_id}`, action)
        );
        yield put({
          type: actions.FETCH_EMPLOYEE_ATTENDANCES_SUCCESS,
          payload: response,
        });
        yield call(action.resolve, response);
      } catch (error) {
        yield put({ type: actions.FETCH_EMPLOYEE_ATTENDANCES_FAILED });
        yield call(action.reject, "reject");
      }
    }
  );

  const fetchAttendance = takeLatest(
  actions.FETCH_EMPLOYEE_ATTENDANCE,
  function* (action: AnyAction): Generator<Effect, void, EmployeeAttendanceReturnType> {
    try {
      const response = yield call(
        api.get,
        `api/employeeAttendance/${action.id}`,
        false
      );
      yield put({
        type: actions.FETCH_EMPLOYEE_ATTENDANCE_SUCCESS,
        payload: response,
      });
      yield call(action.resolve, response);
    } catch (error) {
      yield put({ type: actions.FETCH_EMPLOYEE_ATTENDANCE_FAILED });
      yield call(action.reject, "reject");
    }
  }
);

  const checkIn = takeLatest(
    actions.EMPLOYEE_CHECK_IN,
    function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
      try {
        const response = yield call(
          api.post,
          `api/employeeAttendance/checkIn`,
          action.data,
          null
        );
        yield put({
          type: actions.EMPLOYEE_CHECK_IN_SUCCESS,
          payload: response,
        });
        yield call(action.resolve, response);
      } catch (error) {
        yield put({ type: actions.EMPLOYEE_CHECK_IN_FAILED });
        yield call(action.reject, error);
      }
    }
  );

  const checkOut = takeLatest(
    actions.EMPLOYEE_CHECK_OUT,
    function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
      try {
        const response = yield call(
          api.post,
          `api/employeeAttendance/checkOut`,
          action.data,
          null
        );
        yield put({
          type: actions.EMPLOYEE_CHECK_OUT_SUCCESS,
          payload: response,
        });
        yield call(action.resolve, response);
      } catch (error) {
        yield put({ type: actions.EMPLOYEE_CHECK_OUT_FAILED });
        yield call(action.reject, error);
      }
    }
  );

  const updateAttendance = takeLatest(
    actions.UPDATE_EMPLOYEE_ATTENDANCE,
    function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
      try {
        const response = yield call(
          api.put,
          `api/employeeAttendance/${action.data.id}`,
          action.data
        );
        yield put({
          type: actions.UPDATE_EMPLOYEE_ATTENDANCE_SUCCESS,
          payload: response,
        });
        yield call(action.resolve, response);
      } catch (error) {
        yield put({ type: actions.UPDATE_EMPLOYEE_ATTENDANCE_FAILED });
        yield call(action.reject, "reject");
      }
    }
  );

  export default function* saga() {
    yield all([
      createAttendance,
      checkIn,
      checkOut,
      updateAttendance,
      fetchAttendances,
      fetchAttendance
    ]);
  }
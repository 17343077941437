import React, { LazyExoticComponent } from "react";
import { RestrictedRoutes } from "./interface/RestrictedRoutes.interface";

const restrictedMainRoutes: Array<RestrictedRoutes> = [
  {
    path: "/attendance",
    Component: React.lazy(() => import("pages/attendance")),
    permission: ["attendance:create"],
  },
  {
    path: "/employeeAttendance",
    Component: React.lazy(() => import("pages/employeeAttendance")),
    permission: ["attendance:create"],
  },
  {
    path: "/enrollment/:form_id",
    Component: React.lazy(() => import("pages/enrollment")),
    permission: ["enrollment:create"],
  },
  {
    path: "/leaveRequest",
    Component: React.lazy(
      () => import("pages/LeaveRequests")
    ) as LazyExoticComponent<() => JSX.Element>,
    permission: ["*"],
  },
  {
    path: "/leaveApproval",
    Component: React.lazy(() => import("pages/LeaveRequests/admin")),
    permission: ["*"],
  },
  {
    path: "/leaveApproval/:id",
    Component: React.lazy(() => import("pages/LeaveRequests/admin/detail")),
    permission: ["*"],
  },
  {
    path: "/attendance/:id",
    Component: React.lazy(() => import("pages/attendance/instructor/")),
    permission: ["instructor:view"],
  },
  {
    path: "/leaveList",
    Component: React.lazy(() => import("pages/LeaveRequests/instructor")),
    permission: ["*"],
  },

  {
    path: "/enrolledStudents",
    Component: React.lazy(() => import("pages/admin")) as LazyExoticComponent<
      () => JSX.Element
    >,
    permission: ["*"],
  },
  {
    path: "/enrolledStudents/details",
    Component: React.lazy(
      () => import("pages/admin/details")
    ) as LazyExoticComponent<() => JSX.Element>,
    permission: ["*"],
  },
  {
    path: "/waitinglist",
    Component: React.lazy(
      () => import("pages/pendingStudents")
    ) as LazyExoticComponent<() => JSX.Element>,
    permission: ["waitinglist:view"],
  },

  {
    path: "/child",
    Component: React.lazy(() => import("pages/child")) as LazyExoticComponent<
      () => JSX.Element
    >,
    permission: ["child:view"],
  },
  {
    path: "/child/:id",
    Component: React.lazy(() => import("pages/child")) as LazyExoticComponent<
      () => JSX.Element
    >,
    permission: ["child:view"],
  },
  {
    path: "/child/details/:id",
    Component: React.lazy(
      () => import("pages/child/details")
    ) as LazyExoticComponent<() => JSX.Element>,
    permission: ["child:view"],
  },
  {
    path: "/evaluation-test-requests",
    Component: React.lazy(() => import("pages/evaluationTestRequests")),
    permission: ["*"],
  },
  {
    path: "/timeSlot",
    Component: React.lazy(() => import("pages/timeSlot")),
    permission: ["timeSlot:view"],
  },
  {
    path: "/course",
    Component: React.lazy(() => import("pages/course")),
    permission: ["course:view"],
  },
  {
    path: "/part-time-course",
    Component: React.lazy(() => import("pages/course/partTimeCourse")),
    permission: ["course:view"],
  },
  {
    path: "/course/add",
    Component: React.lazy(() => import("pages/course/add")),
    permission: ["course:create"],
  },
  {
    path: "/course/edit/:id",
    Component: React.lazy(() => import("pages/course/edit")),
    permission: ["course:update"],
  },
  {
    path: "/course/details/:id",
    Component: React.lazy(() => import("pages/course/detail")),
    permission: ["course:view"],
  },
  {
    path: "/branchAdmin",
    Component: React.lazy(() => import("pages/branchAdmin")),
    permission: ["branch_admin:view"],
  },
  {
    path: "/branchAdmin/add",
    Component: React.lazy(() => import("pages/branchAdmin/add")),
    permission: ["branch_admin:create"],
  },
  {
    path: "/branchAdmin/edit/:id",
    Component: React.lazy(() => import("pages/branchAdmin/edit")),
    permission: ["branch_admin:update"],
  },
  {
    path: "/branchAdmin/details/:id",
    Component: React.lazy(() => import("pages/branchAdmin/detail")),
    permission: ["branch_admin:view"],
  },
  {
    path: "/instructor",
    Component: React.lazy(() => import("pages/instructor")),
    permission: ["instructor:view"],
  },

  {
    path: "/instructor/add",
    Component: React.lazy(() => import("pages/instructor/add")),
    permission: ["instructor:create"],
  },
  {
    path: "/instructor/edit/:id",
    Component: React.lazy(() => import("pages/instructor/edit")),
    permission: ["instructor:update"],
  },
  {
    path: "/instructor/details/:id",
    Component: React.lazy(() => import("pages/instructor/detail")),
    permission: ["instructor:view"],
  },
  {
    path: "/branch/add",
    Component: React.lazy(() => import("pages/branch/createEditForm")),
    permission: ["branch:create"],
  },
  {
    path: "/branch/edit/:id",
    Component: React.lazy(() => import("pages/branch/createEditForm")),
    permission: ["branch:update"],
  },
  {
    path: "/branch",
    Component: React.lazy(() => import("pages/branch")),
    permission: ["branch:view"],
  },
  {
    path: "/coupons/add",
    Component: React.lazy(() => import("pages/coupons/createEditForm")),
    permission: ["branch:create"],
  },
  {
    path: "/coupons/edit/:id",
    Component: React.lazy(() => import("pages/coupons/createEditForm")),
    permission: ["branch:update"],
  },
  {
    path: "/coupons",
    Component: React.lazy(() => import("pages/coupons")),
    permission: ["branch:view"],
  },
  {
    path: "/learning-materials",
    Component: React.lazy(() => import("pages/LearningMaterials")),
    permission: ["media:view"],
  },
  {
    path: "/learning-materials/upload",
    Component: React.lazy(() => import("pages/LearningMaterials/upload")),
    permission: ["media:create"],
  },

  {
    path: "/batch",
    Component: React.lazy(() => import("pages/batch")),
    permission: ["batch:view"],
  },
  {
    path: "/batch/add",
    Component: React.lazy(() => import("pages/batch/createEditForm")),
    permission: ["batch:create"],
  },
  {
    path: "/batch/details/:id",
    Component: React.lazy(() => import("pages/batch/details")),
    permission: ["batch:view"],
  },
  {
    path: "/batch/edit/:id",
    Component: React.lazy(() => import("pages/batch/createEditForm")),
    permission: ["batch:update"],
  },
  {
    path: "/bilingandpayments",
    Component: React.lazy(() => import("pages/billing&payments")),
    permission: ["payment:view"],
  },
  {
    path: "/bilingandpayments/:id",
    Component: React.lazy(() => import("pages/billing&payments")),
    permission: ["payment:view"],
  },
  {
    path: "/students/details/:id",
    Component: React.lazy(() => import("pages/students/details")),
    permission: ["*"],
  },
  {
    path: "/students",
    Component: React.lazy(() => import("pages/students")),
    permission: ["*"],
  },
  {
    path: "/notifications",
    Component: React.lazy(() => import("pages/notifications")),
    permission: ["*"],
  },
  {
    path: "/chat",
    Component: React.lazy(() => import("pages/chat")),
    permission: ["*"],
  },
  {
    path: "/assignment",
    Component: React.lazy(
      () => import("pages/assignment")
    ) as LazyExoticComponent<() => JSX.Element>,
    permission: ["*"],
  },
  {
    path: "/assignment/details/:id",
    Component: React.lazy(() => import("pages/assignment/details")),
    permission: ["*"],
  },

  {
    path: "/superAdmin",
    Component: React.lazy(() => import("pages/superadmin")),
    permission: ["super_admin:view"],
  },
  {
    path: "/superAdmin/add",
    Component: React.lazy(() => import("pages/superadmin/add")),
    permission: ["super_admin:create"],
  },
  {
    path: "/superAdmin/edit/:id",
    Component: React.lazy(() => import("pages/superadmin/edit")),
    permission: ["super_admin:update"],
  },
  {
    path: "/customerSupportAdmin",
    Component: React.lazy(() => import("pages/customerSupportAdmin")),
    permission: ["customer_support_admin:view"],
  },
  {
    path: "/customerSupportAdmin/add",
    Component: React.lazy(() => import("pages/customerSupportAdmin/add")),
    permission: ["customer_support_admin:create"],
  },
  {
    path: "/customerSupportAdmin/edit/:id",
    Component: React.lazy(() => import("pages/customerSupportAdmin/edit")),
    permission: ["customer_support_admin:update"],
  },
  {
    path: "/customerSupportAdmin/details/:id",
    Component: React.lazy(() => import("pages/customerSupportAdmin/details")),
    permission: ["customer_support_admin:view"],
  },
  {
    path: "/customerSupportUser",
    Component: React.lazy(() => import("pages/customerSupportUser")),
    permission: ["customer_support_user:view"],
  },
  {
    path: "/customerSupportUser/add",
    Component: React.lazy(() => import("pages/customerSupportUser/add")),
    permission: ["customer_support_user:create"],
  },
  {
    path: "/customerSupportUser/edit/:id",
    Component: React.lazy(() => import("pages/customerSupportUser/edit")),
    permission: ["customer_support_user:update"],
  },
  {
    path: "/customerSupportUser/details/:id",
    Component: React.lazy(() => import("pages/customerSupportUser/details")),
    permission: ["customer_support_user:view"],
  },
  {
    path: "/parentAndChildAssociation",
    Component: React.lazy(
      () => import("pages/parentAndChildAssociation/index")
    ),
    permission: ["*"],
  },
  {
    path: "/newsAndEvents",
    Component: React.lazy(() => import("pages/News&Events")),
    permission: ["*"],
  },
  {
    path: "/newsAndEvents/eventsAdd",
    Component: React.lazy(
      () => import("pages/News&Events/eventsadd")
    ) as LazyExoticComponent<() => JSX.Element>,
    permission: ["*"],
  },
  {
    path: "/newsAndEvents/eventsDetails/:id",
    Component: React.lazy(() => import("pages/News&Events/eventsdetail")),
    permission: ["*"],
  },
  {
    path: "/newsAndEvents/viewAllEvents",
    Component: React.lazy(
      () => import("pages/News&Events/components/ViewAllEvents")
    ),
    permission: ["*"],
  },
  {
    path: "/newsAndEvents/news&eventsAdd",
    Component: React.lazy(
      () => import("pages/News&Events/AddNews&Events")
    ) as LazyExoticComponent<() => JSX.Element>,
    permission: ["*"],
  },

  {
    path: "/newsAndEvents/EditEvent/:id",
    Component: React.lazy(
      () => import("pages/News&Events/EditNews&EventsEvent")
    ) as LazyExoticComponent<() => JSX.Element>,
    permission: ["*"],
  },
  {
    path: "/newsAndEvents/EditNews/:id",
    Component: React.lazy(
      () => import("pages/News&Events/EditNews&EventsNews")
    ) as LazyExoticComponent<() => JSX.Element>,
    permission: ["*"],
  },
  {
    path: "/newsAndEvents/newsDetails/:id",
    Component: React.lazy(
      () => import("pages/News&Events/newsdetail")
    ) as LazyExoticComponent<() => JSX.Element>,
    permission: ["*"],
  },
  {
    path: "/newsAndEvents/viewAllNews",
    Component: React.lazy(
      () => import("pages/News&Events/components/ViewAllNews")
    ),
    permission: ["*"],
  },
  {
    path: "/settings",
    Component: React.lazy(() => import("pages/settings")),
    permission: ["*"],
  },
  {
    path: "/removeChild",
    Component: React.lazy(
      () => import("pages/removeChild")
    ) as LazyExoticComponent<() => JSX.Element>,
    permission: ["*"],
  },
  {
    path: "/removeChildApproval",
    Component: React.lazy(() => import("pages/removeChild/admin")),
    permission: ["*"],
  },
  {
    path: "/removeChildApproval/:id",
    Component: React.lazy(() => import("pages/removeChild/admin/detail")),
    permission: ["*"],
  },
  {
    path: "/logs/details/:id",
    Component: React.lazy(() => import("pages/logs/detail")),
    permission: ["*"],
  },
  {
    path: "/logs",
    Component: React.lazy(() => import("pages/logs")),
    permission: ["*"],
  },
  {
    path: "/branchAssociate",
    Component: React.lazy(() => import("pages/branchAssociate")),
    permission: ["branch_associate:view"],
  },
  {
    path: "/branchAssociate/add",
    Component: React.lazy(() => import("pages/branchAssociate/add")),
    permission: ["branch_associate:create"],
  },
  {
    path: "/branchAssociate/edit/:id",
    Component: React.lazy(() => import("pages/branchAssociate/edit")),
    permission: ["branch_associate:update"],
  },
  {
    path: "/branchAssociate/details/:id",
    Component: React.lazy(() => import("pages/branchAssociate/detail")),
    permission: ["branch_associate:view"],
  },
  {
    path: "/branchSelfService",
    Component: React.lazy(() => import("pages/branchSelfService")),
    permission: ["branch_self_service:view"],
  },
  {
    path: "/branchSelfService/add",
    Component: React.lazy(() => import("pages/branchSelfService/add")),
    permission: ["branch_self_service:create"],
  },
  {
    path: "/branchSelfService/edit/:id",
    Component: React.lazy(() => import("pages/branchSelfService/edit")),
    permission: ["branch_self_service:update"],
  },
  {
    path: "/branchSelfService/details/:id",
    Component: React.lazy(() => import("pages/branchSelfService/detail")),
    permission: ["branch_self_service:view"],
  },
  {
    path: "/surveyform/:parentId/:childId",
    Component: React.lazy(() => import("pages/surveyform")),
    permission: ["survey:update"],
  },
  {
    path: "/survey/:parentId/:childId",
    Component: React.lazy(() => import("pages/survey/component/SurveyAnswers")),
    permission: ["survey:view"],
  },
  {
    path: "/survey",
    Component: React.lazy(() => import("pages/survey")),
    permission: ["survey:view"],
  },
  {
    path: "/trialClassRequest",
    Component: React.lazy(() => import("pages/trialClassRequest")),
    permission: ["trial_class:view"],
  },
  {
    path: "/trialClassRequest/details/:id",
    Component: React.lazy(() => import("pages/trialClassRequest/details/")),
    permission: ["trial_class:view"],
  },
  {
    path: "/appointmentRequest",
    Component: React.lazy(
      () => import("pages/bookAnAppointment/appointmentRequest")
    ),
    permission: ["appointment:view"],
  },
  {
    path: "/appointmentRequest/details/:id",
    Component: React.lazy(() => import("pages/bookAnAppointment/details/")),
    permission: ["appointment:view"],
  },
  {
    path: "/timesheetreport/employee",
    Component: React.lazy(() => import("pages/timeSheetReport/employee")),
    permission: ["*"],
  },
  {
    path: "/timesheetreport/employee/detail/:id",
    Component: React.lazy(
      () => import("pages/timeSheetReport/employee/detail")
    ),
    permission: ["*"],
  },
  {
    path: "/timesheetreport/self/:id",
    Component: React.lazy(() => import("pages/timeSheetReport/selfReport")),
    permission: ["*"],
  },
  {
    path: "/newEnrollment",
    Component: React.lazy(() => import("pages/newEnrollment")),
    permission: ["*"],
  },
  {
    path: "/book-an-appointment",
    Component: React.lazy(() => import("pages/bookAnAppointment")),
    permission: ["*"],
  },
  {
    path: "/referrals",
    Component: React.lazy(() => import("pages/referral")),
    permission: ["*"],
  },
  {
    path: "/manageFund/:id",
    Component: React.lazy(() => import("pages/fundManagement")),
    permission: ["fund:create"],
  },
  {
    path: "/refunds",
    Component: React.lazy(() => import("pages/refund")),
    permission: ["*"],
  },
  {
    path: "/creditanddiscounts",
    Component: React.lazy(() => import("pages/creditAndDiscounts")),
    permission: ["*"],
  },
  {
    path: "/approvebyemployee",
    Component: React.lazy(() => import("pages/attendance/approveByEmployee")),
    permission: ["*"],
  },
  {
    path: "/pickupPerson",
    Component: React.lazy(() => import("pages/pickupPerson")),
    permission: ["*"],
  },
  {
    path: "/manageParent/detail/:id",
    Component: React.lazy(() => import("pages/manageParent")),
    permission: ["*"],
  },
  {
    path: "/manageParent/edit/:id",
    Component: React.lazy(() => import("pages/manageParent/edit")),
    permission: ["*"],
  },
  {
    path: "/branchSelfService/changePassword/:id",
    Component: React.lazy(
      () => import("pages/branchSelfService/changePassword")
    ),
    permission: ["*"],
  },
  {
    path: "/camp",
    Component: React.lazy(() => import("pages/camp")),
    permission: ["course:view"],
  },
  {
    path: "/camp/add",
    Component: React.lazy(() => import("pages/camp/add")),
    permission: ["course:view"],
  },
  {
    path: "/camp/details/:id",
    Component: React.lazy(() => import("pages/camp/detail")),
    permission: ["camp:view"],
  },
  {
    path: "/priceAndPlans",
    Component: React.lazy(() => import("pages/priceAndPlans")),
    permission: ["*"],
  },
  {
    path: "/priceAndPlans/add",
    Component: React.lazy(() => import("pages/priceAndPlans/add")),
    permission: ["*"],
  },
  {
    path: "/priceAndPlans/edit/:id",
    Component: React.lazy(() => import("pages/priceAndPlans/add")),
    permission: ["*"],
  },
  {
    path: "/bulksms",
    Component: React.lazy(() => import("pages/bulkSMS")),
    permission: ["*"],
  },
  {
    path: "/rewards",
    Component: React.lazy(() => import("pages/rewards")),
    permission: ["*"],
  },
  {
    path: "/rewards/add",
    Component: React.lazy(() => import("pages/rewards/add")),
    permission: ["*"],
  },
  {
    path: "/rewards/details/:id",
    Component: React.lazy(() => import("pages/rewards/details")),
    permission: ["*"],
  },
  {
    path: "/changeBranch/detail/:id",
    Component: React.lazy(() => import("pages/changeBranch")),
    permission: ["*"],
  },
  {
    path: "/changeBranch/edit/:id",
    Component: React.lazy(() => import("pages/changeBranch/edit")),
    permission: ["*"],
  },
  {
    path: "/achievement",
    Component: React.lazy(() => import("pages/achievements")),
    permission: ["*"],
  },
  {
    path: "/achievement/create",
    Component: React.lazy(
      () => import("pages/achievements/components/createAchievement")
    ),
    permission: ["*"],
  },
  {
    path: "/achievement/edit/:id",
    Component: React.lazy(
      () => import("pages/achievements/components/createAchievement")
    ),
    permission: ["*"],
  },
  {
    path: "/prizes",
    Component: React.lazy(() => import("pages/prizes")),
    permission: ["*"],
  },
  {
    path: "/redeemedby/:id",
    Component: React.lazy(() => import("pages/redeemedby")),
    permission: ["*"],
  },
  {
    path: "/prizes/add",
    Component: React.lazy(() => import("pages/prizes/add")),
    permission: ["*"],
  },
  {
    path: "/prizes/edit/:id",
    Component: React.lazy(
      () => import("pages/prizes/add")
    ),
    permission: ["*"],
  },
  {
    path: "/disabledate",
    Component: React.lazy(
      () => import("pages/disableDates")
    ),
    permission: ["*"],
  },
  {
    path: "/disabledate/create",
    Component: React.lazy(
      () => import("pages/disableDates/createEditForm")
    ),
    permission: ["*"],
  },
  {
    path: "/disabledate/edit/:id",
    Component: React.lazy(
      () => import("pages/disableDates/createEditForm")
    ),
    permission: ["*"],
  },
  {
    path: "/enrollmentReferral",
    Component: React.lazy(
      () => import("pages/referralSource/enrollmentReferralSource")
    ),
    permission: ["*"],
  },
  {
    path: "/trialClassReferral",
    Component: React.lazy(
      () => import("pages/referralSource/trialClassReferralSource")
    ),
    permission: ["*"],
  },
  {
    path: "/appointmentReferral",
    Component: React.lazy(
      () => import("pages/referralSource/appointmentReferralSource")
    ),
    permission: ["*"],
  },
  {
    path: "/",
    Component: React.lazy(() => import("pages/home")),
    permission: ["*"],
  },
  {
    path: "*",
    Component: React.lazy(() => import("pages/notFound")),
    permission: ["*"],
  },
];

export { restrictedMainRoutes };

import { Drawer, Menu } from "antd";
import { useNavigate } from "react-router-dom";
import Sider from "antd/es/layout/Sider";
import { MenuClickEventHandler } from "rc-menu/lib/interface";
import { MenuItems, SidebarProps } from "../interface/MainLayout.interface";
import {
  CircleIcon,
  CommunicationIcon,
  HomeIcon,
  NotificationIcon,
  SettingIcon,
  UserIcon,
  CuriculumIcon,
} from "assets";
import logo from "assets/images/Logo.png";
import sloganlogo from "assets/images/sloganlogo.png";
import { tokens } from "utils/Constants";
import { BankNotesIcon } from "assets/icons/BankNotesIcon";
import { getUserDeviceResolution, getUserId } from "utils/Helpers";
import { SurveyIcon } from "assets/icons/SurveyIcon";
import { AttendanceIcon } from "assets/icons/AttendanceIcon";
import { useEffect, useState } from "react";
import { FileDoneOutlined } from '@ant-design/icons'
import { CalenderIcon } from "assets/icons/calenderIcon";

interface AMenuItem extends MenuItems {
  type?: "group";
}

type KeyLabelObject = {
  [key: string]: React.ReactNode;
};
var keyLabelObject: KeyLabelObject = {};
function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  href?: String,
  children?: AMenuItem[],
  type?: "group"
): AMenuItem {
  keyLabelObject[key] = label;
  return {
    key,
    icon,
    href,
    children,
    label,
    type,
  } as AMenuItem;
}

export const Sidebar: React.FC<SidebarProps> = (props) => {
  const { open, onClose, onMobile } = props;
  const navigate = useNavigate();
  const [userId, setUserId] = useState<number>();

  useEffect(() => {
    // Retrieve the user ID and set it here
    const userId = getUserId()
    setUserId(userId);
  }, []);

  const onClick: MenuClickEventHandler = ({ key, item }: any) => {
    const label = keyLabelObject[key];
    const href = item.props.href.includes(":id")
      ? item.props.href.replace(":id", userId)
      : item.props.href;
    navigate(href, { state: { label: label } });
    onMobile && onClose(false);
  };

  // const token: string | null = localStorage.getItem("token");
  // const decodedToken: JWTToken = jwt_decode(token || "");

  // const isAuthorized = (permission: string): boolean => {
  //   return true;
  //   // return permissionData(decodedToken).some(element => {
  //   // 	return permission === element;
  //   // });
  // };

  const items: AMenuItem[] = [
    getItem("Dashboards", "1", <HomeIcon style={{ width: "12pt" }} />, "/"),

    getItem(
      "Manage Class",
      "2",
      <UserIcon style={{ width: "12pt" }} />,
      "/user",
      [
        getItem(
          "Enrollment Request",
          "3",
          <CircleIcon style={{ width: "12pt" }} />,
          "/evaluation-test-requests"
        ),
        getItem("Batch", "4", <CircleIcon style={{ width: "12pt" }} />, "/batch"),
        getItem(
          "Enrolled Students",
          "5",
          <CircleIcon style={{ width: "12pt" }} />,
          "/enrolledStudents"
        ),
        getItem(
          "Trial Class Request",
          "6",
          <CircleIcon style={{ width: "12pt" }} />,
          "/trialClassRequest"
        ),
        getItem(
          "Appointment Request",
          "9",
          <CircleIcon style={{ width: "12pt" }} />,
          "/appointmentRequest"
        ),
        getItem(
          "Waiting List",
          "10",
          <CircleIcon style={{ width: "12pt" }} />,
          "/waitinglist"
        ),
      ]
    ),
    getItem(
      "Students",
      "102",
      <UserIcon style={{ width: "12pt" }} />,
      "/students"
    ),
    getItem(
      "Referrals",
      "57",
      <UserIcon style={{ width: "12pt" }} />,
      "/referrals"
    ),
    getItem(
      "Manage Curriculum",
      "7",
      <CuriculumIcon style={{ width: "12pt" }} />,
      "/course",
      [
        getItem(
          "Full Time Course",
          "8",
          <CircleIcon style={{ width: "12pt" }} />,
          "/course"
        ),
        getItem(
          "Part Time Course",
          "54",
          <CircleIcon style={{ width: "12pt" }} />,
          "/part-time-course"
        ),
      ]
    ),

    getItem(
      "User Management",
      "18",
      <NotificationIcon style={{ width: "12pt" }} />,
      "/events",
      [
        getItem(
          "Instructor",
          "19",
          <CircleIcon style={{ width: "12pt" }} />,
          "/instructor"
        ),
        getItem(
          "Branch Associate",
          "27",
          <CircleIcon style={{ width: "12pt" }} />,
          "/branchAssociate"
        ),
        getItem(
          "Branch Self Service",
          "29",
          <CircleIcon style={{ width: "12pt" }} />,
          "/branchSelfService"
        ),
      ]
    ),
    getItem(
      "Leave Request",
      "12",
      <CalenderIcon style={{ width: "12pt" }} />,
      "/leaveApproval"
    ),
    getItem(
      "Achievements & Rewards",
      "30",
      <FileDoneOutlined size={12} />,
      // <BankNotesIcon style={{ width: "12pt" }} />,
      "/achievement",
      [
        getItem(
          "Achievements",
          "39",
          <CircleIcon style={{ width: "12pt" }} />,
          // <BankNotesIcon style={{ width: "12pt" }} />,
          "/achievement"
        ),
        getItem(
          "Rewards",
          "28",
          <CircleIcon style={{ width: "12pt" }} />,
          "/rewards"
        ),
        getItem(
          "Awards",
          "32",
          <CircleIcon style={{ width: "12pt" }} />,
          "/prizes"
        ),
      ]
    ),
    getItem(
      "Timesheet Report",
      "50",
      <AttendanceIcon style={{ width: "12pt" }} />,
      "/timesheetreport",
      [
        getItem(
          "Employee",
          "51",
          <CircleIcon style={{ width: "12pt" }} />,
          "/timesheetreport/employee"
        ),
        getItem(
          "My Timesheet",
          "52",
          <CircleIcon style={{ width: "12pt" }} />,
          `/timesheetreport/self/${userId}`
        ),
      ]
    ),
    getItem(
      "Billing & Payments",
      "22",
      <BankNotesIcon style={{ width: "12pt" }} />,
      "/bilingandpayments"
    ),

    // getItem(
    //   "Redeem",
    //   "31",
    //   <BankNotesIcon style={{ width: "12pt" }} />,
    //   "/redeem",
    //   [
    //     getItem(
    //       "Prizes",
    //       "32",
    //       <CircleIcon style={{ width: "12pt" }} />,
    //       "/prizes"
    //     ),
    //     getItem(
    //       "Redeemed By",
    //       "33",
    //       <CircleIcon style={{ width: "12pt" }} />,
    //       "/redeemedby"
    //     ),
    //   ]
    // ),

    getItem(
      "Disable Dates",
      "34",
      <SettingIcon style={{ width: "12pt" }} />,
      "/disabledate"
    ),

    getItem(
      "Settings",
      "14",
      <SettingIcon style={{ width: "12pt" }} />,
      "/settings",
      [
        getItem(
          "Class Time",
          "15",
          <CircleIcon style={{ width: "12pt" }} />,
          "/timeSlot"
        ),
        getItem(
          "Branch",
          "16",
          <CircleIcon style={{ width: "12pt" }} />,
          "/branch"
        ),
      ]
    ),
    getItem(
      "Communication",
      "26",
      <CommunicationIcon style={{ width: "12pt" }} />,
      "/chat",
      [
        getItem("Chat", "20", <CircleIcon style={{ width: "12pt" }} />, "/chat"),
        getItem(
          "News & Events",
          "25",
          <CircleIcon style={{ width: "12pt" }} />,
          "/newsAndEvents"
        ),
      ]
    ),
    getItem(
      "Referral Source",
      "35",
      <UserIcon style={{ width: "12pt" }} />,
      "/referralSource",
      [
        getItem("Enrollment", "36", <CircleIcon style={{ width: "12pt" }} />, "/enrollmentReferral"),
        getItem(
          "Trial Class",
          "37",
          <CircleIcon style={{ width: "12pt" }} />,
          "/trialClassReferral"
        ),
        getItem(
          "Appointment",
          "38",
          <CircleIcon style={{ width: "12pt" }} />,
          "/appointmentReferral"
        ),
      ]
    ),
    getItem(
      "Survey",
      "101",
      <SurveyIcon style={{ width: "12pt" }} />,
      "/survey"
    ),
  ];

  const content = onMobile ? (
    <Drawer
      placement="left"
      open={open}
      closable={true}
      width={getUserDeviceResolution().width - 80}
      onClose={() => onClose(false)}
      style={{
        overflowX: "hidden",
      }}
    >
      <div
        style={{
          padding: "1.5rem 0",
        }}
      >
        <img alt="logo" src={sloganlogo} width={"80px"} style={{
          marginTop: "-20px",
          marginBottom: "-1rem",
          width: "18rem"
        }} />
      </div>
      <Menu
        onClick={onClick}
        mode="inline"
        defaultSelectedKeys={["1"]}
        items={items}
        style={{
          overflowX: "hidden",
          width: "max-content !important",
          border: "none",
        }}
      />
    </Drawer>
  ) : (
    <Sider
      trigger={null}
      collapsible
      collapsed={open}
      style={{
        overflow: "auto",
        height: "100vh",
        position: "sticky",
        zIndex: "5",
        left: 0,
        top: 0,
        bottom: 0,
        backgroundColor: tokens.BG_LIGHT,
        borderRight: tokens.COLOR_LIGHTBLACK_L50 + " 1px solid",
        width: "max-content",
      }}
    >
      <div
        style={{
          padding: "1.5rem 0.5rem",
        }}
      >
        <img alt="logo" src={sloganlogo} width={"80px"}
          style={{
            marginBottom: "-1rem",
            width: "14rem"
          }}
        />
      </div>

      <Menu
        onClick={onClick}
        mode="inline"
        defaultSelectedKeys={["1"]}
        items={items}
        style={{
          width: "max-content !important",
          paddingBottom: "30px",
        }}
      />
    </Sider>
  );

  return <>{content}</>;
};

export default Sidebar;

const actions = {
  FETCH_BATCHES: "FETCH_BATCHES",
  FETCH_BATCHES_SUCCESS: "FETCH_BATCHES_SUCCESS",
  FETCH_BATCHES_FAILED: "FETCH_BATCHES_FAILED",
  FETCH_BATCHES_BY_INSTRUCTOR: "FETCH_BATCHES_BY_INSTRUCTOR",
  FETCH_BATCHES_BY_INSTRUCTOR_SUCCESS: "FETCH_BATCHES_BY_INSTRUCTOR_SUCCESS",
  FETCH_BATCHES_BY_INSTRUCTOR_FAILED: "FETCH_BATCHES_BY_INSTRUCTOR_FAILED",
  FETCH_BATCH: "FETCH_BATCH",
  FETCH_BATCH_SUCCESS: "FETCH_BATCH_SUCCESS",
  FETCH_BATCH_FAILED: "FETCH_BATCH_FAILED",
  CREATE_BATCH: "CREATE_BATCH",
  CREATE_BATCH_SUCCESS: "CREATE_BATCH_SUCCESS",
  CREATE_BATCH_FAILED: "CREATE_BATCH_FAILED",
  UPDATE_BATCH: "UPDATE_BATCH",
  UPDATE_BATCH_SUCCESS: "UPDATE_BATCH_SUCCESS",
  UPDATE_BATCH_FAILED: "UPDATE_BATCH_FAILED",
  DELETE_BATCH: "DELETE_BATCH",
  DELETE_BATCH_SUCCESS: "DELETE_BATCH_SUCCESS",
  DELETE_BATCH_FAILED: "DELETE_BATCH_FAILED",
  FETCH_TOTAL_STUDENTS_OF_BATCH: "FETCH_TOTAL_STUDENTS_OF_BATCH",
  FETCH_TOTAL_STUDENTS_OF_BATCH_SUCCESS: "FETCH_TOTAL_STUDENTS_OF_BATCH_SUCCESS",
  FETCH_TOTAL_STUDENTS_OF_BATCH_FAILED: "FETCH_TOTAL_STUDENTS_OF_BATCH_FAILED",
  FETCH_TOTAL_STUDENTS_OF_BATCH_FOR_CAMP: "FETCH_TOTAL_STUDENTS_OF_BATCH_FOR_CAMP",
  FETCH_TOTAL_STUDENTS_OF_BATCH_FOR_CAMP_SUCCESS: "FETCH_TOTAL_STUDENTS_OF_BATCH_FOR_CAMP_SUCCESS",
  FETCH_TOTAL_STUDENTS_OF_BATCH_FOR_CAMP_FAILED: "FETCH_TOTAL_STUDENTS_OF_BATCH_FOR_CAMP_FAILED",
  FETCH_BATCHED_WITHOUT_INSTRUCTOR: "FETCH_BATCHED_WITHOUT_INSTRUCTOR",
  FETCH_BATCHED_WITHOUT_INSTRUCTOR_SUCCESS: "FETCH_BATCHED_WITHOUT_INSTRUCTOR_SUCCESS",
  FETCH_BATCHED_WITHOUT_INSTRUCTOR_FAILED: "FETCH_BATCHED_WITHOUT_INSTRUCTOR_FAILED",

  createBatch: (
    data: Object,
    resolve: (data: string) => void,
    reject: (value: string) => void
  ) => ({
    type: actions.CREATE_BATCH,
    data,
    resolve,
    reject,
  }),

  updateForm: (
    data: Object,
    resolve: (data: string) => void,
    reject: (value: string) => void
  ) => ({
    type: actions.UPDATE_BATCH,
    data,
    resolve,
    reject,
  }),

  fetchBatches: (
    // page: number,
    // pageSize: number,
    resolve: (data: string) => void,
    reject: (value: string) => void,
    user_id?: any,
  ) => ({
    type: actions.FETCH_BATCHES,
    // page,
    // pageSize,
    resolve,
    reject,
    user_id,
  }),

  fetchBatchesWithoutInstructor: (
    // page: number,
    // pageSize: number,
    resolve: (data: string) => void,
    reject: (value: string) => void,
  ) => ({
    type: actions.FETCH_BATCHED_WITHOUT_INSTRUCTOR,
    // page,
    // pageSize,
    resolve,
    reject,
  }),

  fetchBatchesByInstructor: (
    resolve: (data: string) => void,
    reject: (value: string) => void
  ) => ({
    type: actions.FETCH_BATCHES_BY_INSTRUCTOR,
    resolve,
    reject,
  }),

  fetchBatch: (
    id: number,
    resolve: (data: string) => void,
    reject: (value: string) => void
  ) => ({
    id,
    type: actions.FETCH_BATCH,
    resolve,
    reject,
  }),

  deleteBatch: (
    id: number,
    resolve: (data: string) => void,
    reject: (value: string) => void
  ) => ({
    type: actions.DELETE_BATCH,
    id,
    resolve,
    reject,
  }),

  fetchTotalStudentOfBatch: (
    courseId: number,
    branchId: number,
    timeSlotId: number,
    daysPerWeek: number,
    daysParam: any,
    resolve: (data: string) => void,
    reject: (value: string) => void
  ) => ({
    type: actions.FETCH_TOTAL_STUDENTS_OF_BATCH,
    courseId,
    branchId,
    timeSlotId,
    daysPerWeek,
    daysParam,
    resolve,
    reject,
  }),

  fetchTotalStudentOfBatchForCamp: (
    daysParam: any,
    branchId: number,
    resolve: (data: string) => void,
    reject: (value: string) => void
  ) => ({
    type: actions.FETCH_TOTAL_STUDENTS_OF_BATCH_FOR_CAMP,
    daysParam,
    branchId,
    resolve,
    reject,
  }),
};

export default actions;
